import { fetchWrapper } from "@/helpers";

const ruta = process.env.VUE_APP_ROOT_API;

export default class PagoClienteService {

  async getAllPagoClientes(datos) { 
    const pagoClientes = await fetchWrapper.post(`${ruta}/pago_clientes`, datos);
    return pagoClientes;
  }
  async actualizoPagoCliente(pagoCliente) {
    const pagoClienteActualizado = await fetchWrapper.put(
      `${ruta}/pago_clientes/` + pagoCliente.id,
      pagoCliente
    );
    return pagoClienteActualizado;
  }

  async getDetallePagos(id) {
    const detallePagos = await fetchWrapper.get(
      `${ruta}/pago_cliente/detalle_pagos/` + id
    );
    return detallePagos;
  }

  async imprimirDetalle(id) {
    return await fetchWrapper.getPdf(
      `${ruta}/pago_cliente/imprimir_detalle/${id}`
    );
  }
}